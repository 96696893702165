import {Injectable} from '@angular/core';
import {ErrorService} from '../../error.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../../user/user.model';
import {UserService} from '../../user/user.service';
import {environment} from '../../../environments/environment';
import {ApiGetGalleryResponse} from './api/api-gallery-response.model';
import {Gallery, GalleryImage} from './gallery.model';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class GalleryService {

  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  public SLIDER_GALERY_ID = 1;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }


  getGallery(galleryId: number): Observable<Gallery> {
    return this.http.get<ApiGetGalleryResponse>(this.apiUrl + '/galleries/' + galleryId + '?langId=' + this.user.languageId)
      .map((response) => {
        const gallery = response.data;
        const galleryImages: GalleryImage[] = []
        for (const image of gallery.items.data) {
          galleryImages.push({
            id: image.id,
            name: image.name,
            description: image.description,
            fileName: image.filename,
            thumbFilename: image.thumbFilename,
            targetUrl: image.targetUrl,
            type: image.type,
            sortOrder: Number(image.sortOrder),
            directoryGuid: image.directoryGuid,
            galleryId: image.gallery_id,
            galleryType: image.galleryType,
            vars: image.vars,
            thumbText: image.thumbText
          })
        }
        return new Gallery(gallery.id, gallery.name, galleryImages);
      })
      .catch(this.errorService.handleError<any>('getGallery'));
  }

  galleryItemSortUp(galleryItemId: number) {
    return this.http
      .post(this.apiUrl + '/galleryItem/' + galleryItemId + '/sortUp', {}, {headers: this.headers})
      .catch(this.errorService.handleError<any>('galleryItemSortUp'));
  }
  galleryItemSortDown(galleryItemId: number) {
    return this.http
      .post(this.apiUrl + '/galleryItem/' + galleryItemId + '/sortDown', {}, {headers: this.headers})
      .catch(this.errorService.handleError<any>('galleryItemSortDown'));
  }

  deleteGalleryItem(galleryItemId: number) {
    return this.http.delete(this.apiUrl + '/galleryItem/' + galleryItemId, {headers: this.headers})
      .catch(this.errorService.handleError<any>('deleteGalleryItem'));
  }

  updateGalleryItem(galleryItemId: any, galleryImageData: any) {
    return this.http.put(this.apiUrl + '/galleryItem/' + galleryItemId + '?langId=' + this.user.languageId,
      JSON.stringify(galleryImageData), {headers: this.headers})
      .catch(this.errorService.handleError<any>('updateGalleryItem'));
  }

  uploadthumbnail(id: number, file) {
    return this.http.post(this.apiUrl + '/uploader',  file, {headers: this.headers})
  }
}
