import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from 'environments/environment';
import 'rxjs/Rx';

import { UserService } from 'app/user/user.service';
import { User } from 'app/user/user.model';
import { ErrorService } from 'app/error.service';
import { EditCategory } from 'app/views/products/categories/edit-category/edit-category.model';
import { ListProduct } from 'app/views/products/product-list/list-product.model';
import { ApiGetCategoriesResponse, ApiGetCategoryResponse } from 'app/views/products/api/api-category-responses.model';
import { ListCategory } from 'app/views/products/categories/category-list/list-category.model';
import {
  ApiAttribute,
  ApiAttributeData,
  ApiGetProductResponse,
  ApiGetProductsResponse,
  ApiUpdateImageResponse,
  ApiCopyProductResponse,
  ApiProductSpecification,
  ApiProductSpecificationRecords
} from 'app/views/products/api/api-product-responses.model';
import { EditProduct } from 'app/views/products/edit-product/edit-product.model';
import { AddProduct } from 'app/views/products/add-product/add-product.model';
import { AddCategory } from 'app/views/products/categories/add-category/add-category.model';
import { ListFeature } from 'app/views/products/features/feature-list/list-feature.model';
import { ApiDeleteFileResponse } from 'app/views/products/api/api-file-responses.model';
import { ApiFeature, ApiGetFeatureResponse, ApiGetFeaturesResponse } from './api-features-responses.model';
import { AddFeature } from '../features/add-feature/add-feature.model';
import { Feature } from '../features/feature.model';
import { FeatureItem } from '../features/featureItem.model';
import { ProductRelatedFeature } from '../edit-product/product-features/product-related-feature.model';
import { ProductRelatedFeatureValue } from '../edit-product/product-features/product-related-feature-value';
import { EditProductImage, EditProductSpecification } from '../edit-product/edit-product.model';
import { ApiGetProductsBasicResponse, ApiParents, ApiProductImages } from './api-product-responses.model';
import { UserLanguage } from 'app/user/user-language.model';

@Injectable()
export class ProductService {
  private headers: HttpHeaders;
  private apiUrl: string;
  private user: User;

  constructor(private http: HttpClient, private userService: UserService, private errorService: ErrorService) {
    this.apiUrl = environment.apiUrl;
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.user = this.userService.getCachedUser();
    this.userService.dataChange.subscribe((user) => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getProductBasicListSize(query?: string, selectedCategoryId?: number, showActive?: number): Observable<number> {
    let params = '?query=';
    if (query) {
      params += query;
    }
    if (selectedCategoryId) {
      params += '&categoryId=' + selectedCategoryId;
    }
    if (Number(showActive) !== -1) {
      params += '&active=' + showActive;
    }

    return this.http.get(this.apiUrl + '/products/basicListSize' + params)
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('getProducts'));
  }

  getOrderedBySortOrderProducts(categoryId: number, showActive: number, withSubcategories?: boolean,
    pageIndex?: number, pageSize?: number, query?: string, sortObject?: any): Observable<ListProduct[]> {
    let params = 'langId=' + this.user.languageId;

    if (categoryId) {
      params += '&categoryId=' + categoryId;
    }
    if (Number(showActive) !== -1) {
      params += '&active=' + showActive;
    }

    if (pageIndex) {
      params += '&page=' + pageIndex;
    } else {
      params += '&page=1';
    }

    if (pageSize) {
      params += '&perPage=' + pageSize;
    } else {
      params += '&perPage=30';
    }

    if (query) {
      params += '&query=' + query;
    }

    if (withSubcategories) {
      params += '&withSubcategories=true';
    }

    if (sortObject !== null && typeof sortObject !== 'undefined' && sortObject.active && sortObject.direction) {
      params += '&sortBy=' + sortObject.active + '&sortDirection=' + sortObject.direction;
    }

    return this.http.get(this.apiUrl + '/products/basicList?' + params)
    .map((response: ApiGetProductsBasicResponse) => {
      let listProducts = [];
      for (const product of response.data) {
        const newListProduct = new ListProduct(
          product.id, product.name, product.catalogueNumber,
          product.category, product.categoryId,
          product.modifiedOn, product.modifiedBy, product.createdOn, product.isActive,
          product.sortOrder
        );
        listProducts.push(newListProduct);
      }

      listProducts = listProducts.sort((product1, product2) => {
        return product1.sortOrder - product2.sortOrder;
      });

      return listProducts;
    })
    .catch(this.errorService.handleError<any>('getProducts'));
  }


  /**/
  /* PRODUCT */
  /**/
  attachDocumentFileToProduct(file: any, productId: number) {
    const body: any = {
      productId: productId,
      fileType: 3,
      languageId: this.user.languageId,
      filename: file.filename
    }
    return this.http
      .post(this.apiUrl + '/products/AttachDocumentFileToProduct', JSON.stringify(body), { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('attachDocumentFileToProduct'));
  }

  detachDocumentFileFromProduct(filename: string, directoryGuid: string, productId: number) {
    const body: any = {
      productId: productId,
      fileType: 3,
      languageId: this.user.languageId,
      filename: filename,
      directoryGuid: directoryGuid,
    }
    return this.http
      .post(this.apiUrl + '/products/DetachDocumentFileFromProduct', JSON.stringify(body), { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('detachDocumentFileFromProduct'));
  }

  getProduct(productId: number): Observable<EditProduct> {
    return this.http.get(this.apiUrl + '/products/' + productId + '?langId=' + this.user.languageId)
      .map((response: ApiGetProductResponse) => {
        const product = response.data;
        const editProduct: EditProduct = {
          name: product.name,
          description: product.description,
          info: product.info,
          shortName: product.shortName,
          catalogueNumber: product.catalogueNumber,
          productGuid: product.productGuid,
          assets: product.assets,
          additionalInfo: product.additionalInfo,
          promotedUntil: product.promotedUntil,
          assemblyDescription: product.assemblyDescription,
          categoryId: product.category.data.id,
          parentId: product.category.data.mainCategoryId,
          instructional_video_link: product.instructional_video_link,
          instructional_video_link_2: product.instructional_video_link_2,
          preventProductCardGeneration: product.product_card_gen_prevent,
          categories: product.categories.data,
          isActive: product.isActive,
          isVisibleOnList: product.isVisibleOnList,
          inVisibleOnSearch: product.inVisibleOnSearch,
          isVirtualProduct: product.isVirtualProduct,
          documents: product.documents.data,
          productImages: product.images.data.filter(image => image.galleryTypeId === 1),
          usageImages: product.images.data.filter(image => image.galleryTypeId === 2),
          technicalDrawingImages: product.images.data.filter(image => image.galleryTypeId === 4),
          featureItems: product.featureItems.data,
          specificationRecords: product.specificationRecords.data,
          childs: product.childs.data,
          attribs: product.attribs.data,
          image: product.image,
          historyRecords: product.historyRecords
        };

        editProduct.documents.forEach(d => {
          if (d.name.endsWith('pdf')) {
            d.thumbFilename = 'assets/img/PDF_thumbnail.png'
          }
        });

        return editProduct;

      })
      .catch(this.errorService.handleError<any>('getProduct'));
  }

  copyProductRequest(productId: number): Observable<ApiCopyProductResponse> {
    return this.http.post(this.apiUrl + '/products/copyProduct', { productId: productId, langId: this.user.languageId }, { headers: this.headers })
      .map(response => {
        return response
      }).catch(this.errorService.handleError<any>('copyProductRequest'))
  }

  getProductsForRelation(productId: number): Observable<ListProduct[]> {
    return this.http.post(this.apiUrl + '/products/SearchForProductsForRelation' + '?langId=' + this.user.languageId, { productId: productId }, { headers: this.headers })
      .map((response: ApiGetProductsResponse) => {
        let listProducts = [];
        for (const product of response.data) {
          const newListProduct = new ListProduct(
            product.id, product.name, product.catalogueNumber,
            product.category.data.name, product.category.data.id,
            product.modifiedOn, product.historyRecords, product.createdOn, product.isActive,
            product.sortOrder
          );
          listProducts.push(newListProduct);
        }

        listProducts = listProducts.sort((product1, product2) => {
          return product1.sortOrder - product2.sortOrder;
        });

        return listProducts;
      })
      .catch(this.errorService.handleError<any>('getProducts'));
  }

  getRelationBasicList() {
    return this.http.get<ApiGetProductsBasicResponse>(this.apiUrl + '/products/getRelationBasicList' + '?langId=' + this.user.languageId, { headers: this.headers })
      .map(response => response.data)
      .catch(this.errorService.handleError<any>('getRelationBasicList'));
  }

  getProductBasicList() {
    return this.http.get<ApiGetProductsBasicResponse>(this.apiUrl + '/products/basicList' + '?langId=' + this.user.languageId, { headers: this.headers })
      .map(response => response.data)
      .catch(this.errorService.handleError<any>('getProductsBasicList'));
  }

  addProduct(product: AddProduct): Observable<ApiGetProductResponse> {
    return this.http
      .post(this.apiUrl + '/products?langId=' + this.user.languageId, JSON.stringify(product), { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('addProduct'));
  }

  updateProduct(productId: number, product: EditProduct): Observable<ApiGetProductResponse> {
    return this.http
      .put(this.apiUrl + '/products/' + productId + '?langId=' + this.user.languageId, JSON.stringify(product), { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('updateProduct'));
  }

  preventProductCardGeneration(productId: number, value: boolean): Observable<ApiGetProductResponse> {
    return this.http
      .put(this.apiUrl + '/products/' + productId + '?langId=' + this.user.languageId + '&saveHistory=12',
        JSON.stringify({ product_card_gen_prevent: value }),
        { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('updateProduct'));
  }

  addRelation(productId: number, relatedProductId: number, bindingAngle?: number, mutual?: boolean): Observable<ApiGetProductResponse> {
    return this.http
      .post(this.apiUrl + '/products/AddRelation?langId=' + this.user.languageId, {
        productId: productId,
        relatedProductId: relatedProductId,
        bindingAngle: bindingAngle,
        mutual: mutual
      }, { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('addRelation'));
  }

  updateRelation(productId: number, relatedProductId: number, bindingAngle: number): Observable<ApiGetProductResponse> {
    return this.http
      .post(this.apiUrl + '/products/' + productId + '/UpdateRelationParams?langId=' + this.user.languageId, {
        relatedProductId: relatedProductId,
        bindingAngle: bindingAngle
      }, { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('updateRelation'));
  }

  removeRelation(productId: number, relatedProductId: number): Observable<ApiGetProductResponse> {
    return this.http
      .post(this.apiUrl + '/products/RemoveRelation', { productId: productId, relatedProductId: relatedProductId },
        { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('removeRelation'));
  }

  updateProductSortUp(productId: number): Observable<ApiGetProductResponse> {
    return this.http
      .post(this.apiUrl + '/products/sortUp', { productId: productId }, { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('updateProductSortUp'));
  }

  updateProductSortDown(productId: number): Observable<ApiGetProductResponse> {
    return this.http
      .post(this.apiUrl + '/products/sortDown', { productId: productId }, { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('updateProductSortUp'));
  }

  updateProductSort(productId: number, position: number): Observable<ApiGetProductResponse> {
    return this.http
      .post(this.apiUrl + '/products/' + productId + '/sort', { sortPosition: position }, { headers: this.headers })
      .map(response => {
        this.getRelationBasicList()
      })
      .catch(this.errorService.handleError<any>('updateProductSort'));
  }

  addAttrib(productId: number, attrib: string): Observable<ApiAttribute[]> {
    return this.http
      .post(this.apiUrl + '/products/AddAttrib?langId=' + this.user.languageId,
        { productId: productId, attrib: attrib }, { headers: this.headers })
      .map((response: ApiGetProductResponse) => {
        return response.data.attribs.data;
      })
      .catch(this.errorService.handleError<any>('addAttrib'));
  }

  deleteAttrib(attribId: number): Observable<ApiAttribute> {
    return this.http
      .delete(this.apiUrl + '/products/DeleteAttrib/' + attribId, { headers: this.headers })
      .map((response: ApiAttributeData) => {
        return response.data;
      })
      .catch(this.errorService.handleError<any>('deleteAttrib'));
  }

  updateAttrib(attribId: number, attrib: string): Observable<ApiAttribute> {
    return this.http
      .post(this.apiUrl + '/products/UpdateAttrib?langId=' + this.user.languageId,
        { attrib_Id: attribId, attrib: attrib }, { headers: this.headers })
      .map((response: ApiAttributeData) => {
        return response.data;
      })
      .catch(this.errorService.handleError<any>('updateAttrib'));
  }
  attributeSortUp(attribId: number): Observable<ApiAttribute[]> {
    return this.http.post(this.apiUrl + '/productAttribute/' + attribId + '/sortUp?langId=' + this.user.languageId, {}, { headers: this.headers })
      .map((response: ApiAttributeData) => response.data)
      .catch(this.errorService.handleError<any>('attributeSortUp'));

  }
  attributeSortDown(attribId: number): Observable<ApiAttribute[]> {
    return this.http.post(this.apiUrl + '/productAttribute/' + attribId + '/sortDown?langId=' + this.user.languageId, {}, { headers: this.headers })
      .map((response: ApiAttributeData) => response.data)
      .catch(this.errorService.handleError<any>('attributeSortDown'));
  }

  specificationSortUp(specId: number) {
    return this.http.post<ApiGetProductResponse>(this.apiUrl + '/productSpecification/' + specId + '/sortUp?langId=' + this.user.languageId, {}, { headers: this.headers })
      .map(r => r.data.specificationRecords.data)
      .catch(this.errorService.handleError<any>('specificationSortUp'));
  }
  specificationSortDown(specId: number) {
    return this.http.post<ApiGetProductResponse>(this.apiUrl + '/productSpecification/' + specId + '/sortDown?langId=' + this.user.languageId, {}, { headers: this.headers })
      .map(r => r.data.specificationRecords.data)
      .catch(this.errorService.handleError<any>('specificationSortDown'));
  }

  updateFile(fileId: number, fileTypeId: number, name: string): Observable<ApiUpdateImageResponse> {
    let payload;
    if (fileTypeId == 3 || fileTypeId == 1 || fileTypeId == 2 || fileTypeId == 4) {
      payload = { name: name, fileType: fileTypeId };
    } else {
      payload = { name: name };
    }

    return this.http
      .put(this.apiUrl + '/files/' + fileId, payload, { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('updateImage'));
  }

  deleteFile(directoryGuid: string, fileType?: number): Observable<ApiDeleteFileResponse> {
    let req: string;
    if (fileType) {
      req = this.apiUrl + '/uploader/' + directoryGuid + '?fileType=' + fileType;
    } else {
      req = this.apiUrl + '/uploader/' + directoryGuid;
    }

    return this.http
      .delete(req, { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('deleteFile'));
  }

  deleteProduct(productId: number): Observable<ApiDeleteFileResponse> {
    return this.http
      .delete(this.apiUrl + '/products/' + productId, { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('deleteFile'));
  }

  /**/
  /* CATEGORY */
  /**/

  getCategories(): Observable<ListCategory[]> {
    return this.http.get(this.apiUrl + '/categories?langId=' + this.user.languageId)
      .map((response: ApiGetCategoriesResponse) => {
        const listCategories = [];
        for (const category of response.data) {
          const newListCategory = new ListCategory(
            category.id, category.name, category.description, category.mainCategoryId,
            category.parentCategoryId, category.modifiedOn, category.createdOn,
            category.isActive, category.sortOrder
          );
          listCategories.push(newListCategory);
        }
        return listCategories;
      })
      .catch(this.errorService.handleError<any>('getProductCategories'));
  }

  getOnlyMainAndSubCategories(): Observable<ListCategory[]> {
    return this.http.get(this.apiUrl + '/rootCategories?langId=' + this.user.languageId)
      .map((response: ApiGetCategoriesResponse) => {
        const listCategories = [];
        for (const category of response.data) {
          const newListCategory = new ListCategory(
            category.id, category.name, category.description, category.mainCategoryId,
            category.parentCategoryId, category.modifiedOn, category.createdOn,
            category.isActive, category.sortOrder
          );
          listCategories.push(newListCategory);
        }
        return listCategories;
      })
      .catch(this.errorService.handleError<any>('getProductCategories'));
  }

  getCategory(categoryId: number): Observable<EditCategory> {
    return this.http.get(this.apiUrl + '/categories/' + categoryId + '?langId=' + this.user.languageId)
      .map((response: ApiGetCategoryResponse) => {
        const category = response.data;
        return new EditCategory(category.name, category.meta_title, category.meta_keywords, category.meta_description, category.description, category.isActive,
          category.parentCategoryId);
      })
      .catch(this.errorService.handleError<any>('getProducts'));
  }

  getAllCategoryChildIds(categoryId: number): Observable<number[]> {
    return this.http.get(this.apiUrl + '/categories/' + categoryId + '?langId=' + this.user.languageId)
      .map((response: ApiGetCategoryResponse) => {
        return response.data.childs.data.map(child => child.id);
      })
      .catch(this.errorService.handleError<any>('getAllCategoryChildIds'));
  }

  addCategory(category: AddCategory): Observable<AddCategory> {
    return this.http
      .post(this.apiUrl + '/categories?langId=' + this.user.languageId, JSON.stringify(category), { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('addProductCategory'));
  }

  updateCategory(categoryId: number, category: EditCategory): Observable<ApiGetCategoryResponse> {
    return this.http
      .put(this.apiUrl + '/categories/' + categoryId + '?langId=' + this.user.languageId, JSON.stringify(category), { headers: this.headers })
      .map(response => {
        return response;
      })
      .catch(this.errorService.handleError<any>('updateProductCategory'));
  }

  deleteCategory(categoryId: number) {
    return this.http
      .delete(this.apiUrl + '/categories/' + categoryId + '?langId=' + this.user.languageId, { headers: this.headers })
      .catch(this.errorService.handleError<any>('deleteCategory'));
  }

  getProductCategories(productId: number): Observable<any> {
    return this.http
      .get(this.apiUrl + '/products/' + productId + '/categories');
  }

  categorySortUp(categoryId: any) {
    return this.http.put(this.apiUrl + '/categories/' + categoryId + '/sortUp', {}, { headers: this.headers })
      .map(() => { })
      .catch(this.errorService.handleError<any>('categorySortUp'));
  }

  categorySortDown(categoryId: any) {
    return this.http.put(this.apiUrl + '/categories/' + categoryId + '/sortDown', {}, { headers: this.headers })
      .map(() => { })
      .catch(this.errorService.handleError<any>('categorySortDown'));
  }

  getFeatures(): Observable<ListFeature[]> {
    //   return Observable.forkJoin([
    //     this.countryService.getCountries(),
    //     this.http.get(this.apiUrl + '/distributors?langId=' + this.user.languageId)
    //   ])
    //     .map((data: any[]) => {
    //       const countries: Country[] = data[0];
    //       const distributors: ApiDistributor[] = data[1];
    //       const listDistributors: Distributor[] = [];
    //       for (const distributor of distributors) {
    //         const country: Country = countries.filter(c => c.id === distributor.country_id)[0];
    //
    //         const row = new Distributor(
    //           distributor.id, distributor.name, distributor.address, distributor.postalCode, distributor.city,
    //           distributor.country_id, country,
    //           distributor.tel, distributor.tel2, distributor.email,
    //           distributor.www, distributor.latitude, distributor.longitude, distributor.isActive
    //         );
    //         listDistributors.push(row);
    //       }
    //       return listDistributors;
    //     })
    //     .catch(this.errorService.handleError<any>('getDistributors'));
    // }

    return Observable.forkJoin([
      this.getCategories(),
      this.http.get(this.apiUrl + '/features' + '?langId=' + this.user.languageId)
    ])
      .map((data: any[]) => {
        const categories: ListCategory[] = data[0];
        const featuresResponse: ApiGetFeaturesResponse = data[1];
        const listFeatures = [];
        for (const feature of featuresResponse.data) {
          const categoryId: number = Number(feature.categoryId);
          const category: ListCategory = categories.filter(c => c.id === categoryId)[0];
          const categoryName: string = typeof category === 'undefined' ? '' : category.name;
          const newListFeature = new ListFeature(
            feature.id, feature.name, feature.categoryId, categoryName,
            feature.createdOn, feature.modifiedOn, feature.deletedOn,
            feature.isActive, feature.isVisibleOnSite, feature.sortOrder
          );
          listFeatures.push(newListFeature);
        }
        return listFeatures;
      })
      .catch(this.errorService.handleError<any>('getProductFeatures'));
  }

  addFeature(addFeature: AddFeature) {
    return this.http
      .post(this.apiUrl + '/features?langId=' + this.user.languageId, JSON.stringify(addFeature), { headers: this.headers })
      .catch(this.errorService.handleError<any>('addProductFeature'));
  }

  getFeature(featureId: number): Observable<Feature> {
    return this.http.get(this.apiUrl + '/features/' + featureId + '?langId=' + this.user.languageId)
      .map((response: ApiGetFeatureResponse) => {
        const apiFeature: ApiFeature = response.data;
        const items: FeatureItem[] = apiFeature.items.data.sort((item1, item2) => {
          return item1.sortOrder - item2.sortOrder
        })

        const feature: Feature = {
          id: apiFeature.id,
          name: apiFeature.name,
          categoryId: apiFeature.categoryId,
          items: items,
          isRangeFeature: apiFeature.isRangeFeature,
          rangeUnit: apiFeature.rangeUnit,
          isActive: apiFeature.isActive,
          isVisibleOnSite: apiFeature.isVisibleOnSite,
          sortOrder: apiFeature.sortOrder
        }
        return feature;
      })
      .catch(this.errorService.handleError<any>('getFeature'));
  }

  updateFeature(featureId: number, feature: Feature) {
    return this.http
      .put(this.apiUrl + '/features/' + featureId + '?langId=' + this.user.languageId, JSON.stringify(feature), { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateFeature'));
  }

  deleteFeature(featureId: number) {
    return this.http
      .delete(this.apiUrl + '/features/' + featureId + '?langId=' + this.user.languageId, { headers: this.headers })
      .catch(this.errorService.handleError<any>('deleteFeature'));
  }

  featureSortUp(featureId: number) {
    return this.http.post(this.apiUrl + '/features/' + featureId + '/sortUp', {}, { headers: this.headers })
      .map(() => { })
      .catch(this.errorService.handleError<any>('featureSortUp'));
  }
  featureSortDown(featureId: number) {
    return this.http.post(this.apiUrl + '/features/' + featureId + '/sortDown', {}, { headers: this.headers })
      .map(() => { })
      .catch(this.errorService.handleError<any>('featureSortUp'));
  }
  featureItemStortUp(featureItemId: number) {
    return this.http.post(this.apiUrl + '/featureItem/' + featureItemId + '/sortUp', {}, { headers: this.headers })
      .map(() => { })
      .catch(this.errorService.handleError<any>('featureItemSortUp'));
  }
  featureItemStortDown(featureItemId: number) {
    return this.http.post(this.apiUrl + '/featureItem/' + featureItemId + '/sortDown', {}, { headers: this.headers })
      .map(() => { })
      .catch(this.errorService.handleError<any>('featureItemSortDown'));
  }

  addFeatureItem(featureId: number, itemName: string, itemMinValue: number, itemMaxValue: number): Observable<FeatureItem[]> {
    const data: any = { featureId: featureId }
    if (itemName) {
      data.name = itemName;
    }
    if (itemMinValue) {
      data.minIntValue = itemMinValue;
    }
    if (itemMaxValue) {
      data.maxIntValue = itemMaxValue;
    }

    return this.http
      .post(this.apiUrl + '/features/StoreFeatureItem?langId=' + this.user.languageId, data, { headers: this.headers })
      .map((response: ApiGetFeatureResponse) => {
        const apiFeature: ApiFeature = response.data;
        return apiFeature.items.data;
      })
      .catch(this.errorService.handleError<any>('addFeatureItem'));
  }

  deleteFeatureItem(featureItemId: number) {
    return this.http
      .delete(this.apiUrl + '/features/DeleteFeatureItem/' + featureItemId + '?langId=' + this.user.languageId, { headers: this.headers })
      .catch(this.errorService.handleError<any>('deleteFeatureItem'));
  }

  updateFeatureItem(featureItemId: number, itemName: string, featureMinIntValue: number, featureMaxIntValue: number): Observable<FeatureItem[]> {
    return this.http
      .put(this.apiUrl + '/features/UpdateFeatureItem' + '?langId='
        + this.user.languageId, JSON.stringify({ name: itemName, featureItemId: featureItemId, minIntValue: featureMinIntValue, maxIntValue: featureMaxIntValue }), { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateFeature'));
  }

  updateFeatureItemVisiblityOnSite(isVisibleOnSite: boolean, featureItemId: number): Observable<FeatureItem[]> {
    return this.http
      .post(this.apiUrl + '/featureItem/' + featureItemId + '/updateFeatureItemVisiblityOnSite' + '?langId=' + this.user.languageId, { isVisibleOnSite: isVisibleOnSite, featureItemId: featureItemId }, { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateFeature'));
  }

  updateFeatureItemVisiblityOnConfigurator(isVisibleOnConfigurator: boolean, featureItemId: number): Observable<FeatureItem[]> {
    return this.http
      .post(this.apiUrl + '/featureItem/' + featureItemId + '/updateFeatureItemVisiblityOnConfigurator' + '?langId=' + this.user.languageId, { isVisibleOnConfigurator: isVisibleOnConfigurator, featureItemId: featureItemId }, { headers: this.headers })
      .catch(this.errorService.handleError<any>('updateFeature'));
  }

  getProductRelatedFeatures(productFeatureItems: FeatureItem[], categoryRelatedFeatures: Observable<Feature[]>): Observable<ProductRelatedFeature[]> {
    return categoryRelatedFeatures.map(features => {
      const productRelatedFeature: ProductRelatedFeature[] = [];
      for (const f of features) {
        const productRelatedFeatureValues: ProductRelatedFeatureValue[] = [];

        for (const featureItem of f.items) {
          const selected: FeatureItem = productFeatureItems.find(i => i.id === featureItem.id);
          const featureItemVale: ProductRelatedFeatureValue = {
            featureItem: featureItem,
            selected: selected != null,
            value: selected != null ? selected.bindingValue.value : null
          }
          productRelatedFeatureValues.push(featureItemVale);
        }
        productRelatedFeature.push(new ProductRelatedFeature(f, productRelatedFeatureValues));
      }
      return productRelatedFeature;
    }).catch(this.errorService.handleError<any>('productRelatedFeatures'));
  }

  addFeatureItemToProduct(productId: number, featureItemId: number, value: number): Observable<ApiGetProductResponse> {
    return this.http
      .post(this.apiUrl + '/products/AddFeatureItemToProduct', JSON.stringify({ productId: productId, featureItemId: featureItemId, value: value }), { headers: this.headers })
      .catch(this.errorService.handleError<any>('addFeatureItemToProduct'));
  }

  removeFeatureItemFromProduct(productId: number, featureItemId: number, value: number) {
    return this.http
      .post(this.apiUrl + '/products/RemoveFeatureItemFromProduct', JSON.stringify({ productId: productId, featureItemId: featureItemId }), { headers: this.headers })
      .catch(this.errorService.handleError<any>('removeFeatureItemFromProduct'));
  }

  addSpecificationRecord(productId: number, specification: EditProductSpecification) {
    return this.http
      .post<ApiGetProductResponse>(this.apiUrl + '/products/' + productId + '/SaveProdSpecRecord' + '?langId=' + this.user.languageId, JSON.stringify(specification), { headers: this.headers })
      .map(product => product.data.specificationRecords.data)
      .catch(this.errorService.handleError<any>('addSpecificationRecord'));
  }

  updateSpecificationRecord(specification: EditProductSpecification) {
    return this.http
      .put<ApiGetProductResponse>(this.apiUrl + '/products/UpdateProdSpecRecord/' + specification.id + '?langId=' + this.user.languageId, JSON.stringify(specification), { headers: this.headers })
      .map(r => r.data.specificationRecords.data)
      .catch(this.errorService.handleError<any>('deleteFeatureItem'));
  }

  deleteSpecificationRecord(specificationId: number) {
    return this.http
      .delete(this.apiUrl + '/products/DeleteProdSpecRecord/' + specificationId, { headers: this.headers })
      .catch(this.errorService.handleError<any>('deleteFeatureItem'));
  }

  getParents(productId: number) {
    return this.http.get<ApiParents>(this.apiUrl + '/products/' + productId + '/parents' + '?langId=' + this.user.languageId)
      .map(response => response.data);
  }

  imageMoveBefore(id: number, id2: number, fileType: any): Observable<EditProductImage[]> {
    return this.http
      .put<ApiProductImages>(this.apiUrl + '/products/photo/' + id + '/moveBefore/' + id2 + '?fileType=' + fileType, { headers: this.headers })
      .map(response => response.data)
      .catch(this.errorService.handleError<any>('imageMoveBefore'));
  }

  imageMoveAfter(id: number, id2: number, fileType: any): Observable<EditProductImage[]> {
    return this.http
      .put<ApiProductImages>(this.apiUrl + '/products/photo/' + id + '/moveAfter/' + id2 + '?fileType=' + fileType, { headers: this.headers })
      .map(response => response.data)
      .catch(this.errorService.handleError<any>('imageMoveAfter'));
  }

  documentMoveAfter(id: number, id2: number): Observable<EditProductImage[]> {
    return this.http
      .put<ApiProductImages>(this.apiUrl + '/products/document/' + id + '/moveAfter/' + id2 + '?langId=' + this.user.languageId, { headers: this.headers })
      .map(response => response.data)
      .catch(this.errorService.handleError<any>('imageMoveBefore'));
  }

  documentMoveBefore(id: number, id2: number): Observable<EditProductImage[]> {
    return this.http
      .put<ApiProductImages>(this.apiUrl + '/products/document/' + id + '/moveBefore/' + id2 + '?langId=' + this.user.languageId, { headers: this.headers })
      .map(response => response.data)
      .catch(this.errorService.handleError<any>('imageMoveAfter'));
  }

}
